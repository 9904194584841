.offersSliderDesktop {
	margin-top: 1.5em;
	margin-left: 1em;

	.offersBar {
		width: 98%;
		margin-top: 0.5em;
		min-height: 14em;

		.offerCard {
			margin: 0.5em;
			background: #ffffff;
			box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.07);
			border-radius: 20px;
			width: 29em;
			height: 12.5em;
			padding: 0;
			overflow: hidden;
			cursor: pointer;
			img {
				width: 100%;
				height: 100%;
			}
			@supports (object-fit: cover) {
				img {
					object-fit: cover;
					object-position: center center;
				}
			}
		}

		.react-horizontal-scrolling-menu--wrapper {
			flex-wrap: wrap;
			justify-content: flex-end;
		}
		//react-horizontal-scrolling-menu items ⬇
		// .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
		// 	display: none;
		// }

		// .react-horizontal-scrolling-menu--scroll-container {
		// 	-ms-overflow-style: none; /* IE and Edge */
		// 	scrollbar-width: none; /* Firefox */
		// 	order: 1;
		// 	flex-basis: 100%;
		// 	flex-shrink: 0;
		// }
		.arrows-container {
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			background: $primary-blue;
			box-shadow: 0px 4px 8px rgba(33, 74, 142, 0.5);
			border-radius: 30px;
			width: 2.5em;
			height: 2.5em;
			position: absolute;
			/*margin-top: -2.5em;*/
			margin: 5rem -0.5rem 0.5em 0.2em;
			z-index: 1;

			&.-disabled {/*
				background: none;
				box-shadow: none;
				cursor: inherit;*/
			}
			&.-left {
				left: 0;
			}
		}
	}
}
