.mercados-desktop {
	height: 100%;
	display: flex;
	flex-direction: column;

	.principal-container {
		flex-grow: 1;
		height: 92%;
	}
}

.list-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.4rem;
	height: 100%;

	justify-content: space-between;

	@media screen and (max-width: 1280px) {
		height: fit-content;
	}

	.scrollable-list {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 0.875rem;
		height: 87%;
		overflow: auto;

		box-sizing: border-box;
		padding-right: 0.5rem;
	}
}
