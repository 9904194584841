.editar-datos-title {
	line-height: 20px;
	display: block;
}

.editar-datos-form {
	margin-top: 2.4rem;
	width: 100%;
}

.editar-datos-button {
	margin-top: 3.125rem;
}
