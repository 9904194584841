.product-list {
	display: flex;
	flex-direction: column;
	gap: 0.875rem;
  width: 100%;
	.mproducts-resume {
		margin-top: 24px;
		border-top: 1px solid $border-color;
		box-sizing: border-box;
		padding-top: 0.5em;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		div {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}

		div:nth-child(2n) {
			align-items: flex-end;
		}
		div:nth-child(2n + 1) {
			align-items: flex-start;
		}
	}

	.mproducts-group {
		display: flex;
		flex-direction: column;
		gap: 0.875rem;
	}
}
