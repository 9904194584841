.cart-menu {
	width: 100%;

	.cart-title {
		text-align: center;
		display: block;
	}

	.cart-menu-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		.content {
			box-sizing: border-box;
			width: 100%;
			border-top: 1px solid $border-color;

			margin-top: 1.938rem;

			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			padding-top: 0.5rem;

			div.price,
			div.description {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
			div.price {
				align-items: flex-end;
			}
		}
		.message {
			margin-top: 0.5rem;
		}
	}

	.cart-button {
		margin-top: 1.938rem;
	}
}
