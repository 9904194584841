.account-container-desktop {
	@import './Direcciones/direccionesDesktop';
	@import './UserInfo/userInfoDesktop';
	@import './EditarDatos/editarDatosDesktop';
	@import './Mercados/mercadosDesktop';
	@import './Tarjetas/tarjetasDesktop';
	@import './Historial/historialDesktop';

	.account-body {
		width: 100%;
		height: 620px;
		display: flex;
		gap: 1rem;

		.account-menu {
			height: 100%;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			.account-navigation {
				width: 100%;
				box-sizing: border-box;
				padding: 2.3rem 0;
				display: flex;
				flex-direction: column;
				gap: 1.461rem;
			}
		}

		.account-component {
			box-sizing: border-box;
			padding: 2.37rem;
			border-radius: 18px;
			background-color: rgba(22, 65, 148, 0.05);

			width: 60%;
			color: black;

			overflow-y: auto;
			height: 100%;

			.section-title {
				display: block;
				margin-bottom: 1.786rem;
			}
		}
	}
}
