@import './base/base';
@import './components/components';
@import './pages/pages';
@import './atoms/atoms';

body {
	margin: 0;
	background-image: url('../../assets/illustrations/background.svg');
}

input,
textarea,
button,
select,
a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.desktop-page-wrapper {
	box-sizing: border-box;
	margin: 3.13rem 7rem;
	min-height: 50vh;

	//breakpoints
	@media screen and (max-width: 1200px) {
		margin: 3.13rem 3.5rem;
	}
	@media screen and (max-width: 720px) {
		margin: 3.13rem 1rem;
	}
}

.desktop-page-wide {
	box-sizing: border-box;
	padding: 1.313rem 0.875rem;
}

.mobile-page-wrapper {
	box-sizing: border-box;
	margin: 1rem 0.75rem;
}

.cursor-pointer {
	cursor: pointer;
}

iframe {
	display: none;
}
