.producto-general {
	background-color: #fff;
	box-sizing: border-box;
	overflow: hidden;
	height: 6.625rem;

	/* display: flex;
    flex-direction: row;
    align-items: flex-start; */
	display: grid;
	grid-template-columns: 28% 72%;
	grid-template-rows: 100%;
	align-items: flex-start;
	padding: 11px 11px 11px 5px;

	box-shadow: 0px 5px 20px rgba(33, 74, 142, 0.15);
	border-radius: 10px;

	.product-img {
		position: inherit;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			max-height: 100%;
			box-sizing: border-box;
			padding-right: 0.3rem;
			object-fit: contain;
			max-width: 100%;
		}
	}

	.product-data {
		flex-grow: 1;

		.product-info {
			a {
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.product-prices {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				margin-top: 7px;
				padding-bottom: 7px;
				border-bottom: 1px solid $border-color;

				.price {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 0.5em;
				}
			}
		}
		.product-actions {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			padding-top: 7px;
			i {
				cursor: pointer;
			}
		}
	}
	.product-description {
		display: none;
	}
}
.producto-general.productObservations {
	height: 8rem;
	overflow-y: auto;

	.product-description {
		margin: 0;
		display: block;
		color: gray;
		font-size: 0.8rem;
		border-left: solid #164194 2px;
		padding: 0.2rem 0.5rem;
		margin-top: 0.5rem;
	}
}

::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #00000018;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.156);
	border-radius: 10px;
}
