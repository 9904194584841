.addresses-desktop {
	height: 100%;
	display: flex;
	flex-direction: column;

	.addresses-container {
		box-sizing: border-box;
		flex-grow: 1;
		overflow: hidden;

		.addresses-cards {
			display: flex;
			flex-direction: column;
			gap: 1.188rem;
		}

		.addresses-form {
			height: 100%;
			overflow-y: auto;
		}
	}

	@media screen and (max-width: 1280px) {
		.addresses-container {
			overflow: unset;
		}
	}
}
