.cart-navigation {
	display: flex;
	align-items: center;
	gap: 1.69rem;
	margin-bottom: 1.813rem;

	.nav-steps {
		display: flex;
		align-items: center;
		gap: 0.625rem;

		.nav-bubble {
			width: 2.25rem;
			height: 2.25rem;
			border-radius: 50%;

			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;

			&.-lower {
				opacity: 0.3;
				background-color: $primary-blue;
				border: 1px solid transparent;
				span {
					color: #fff;
				}
			}
			&.-exact {
				background-color: $primary-blue;
				border: 1px solid $primary-blue;
				span {
					color: #fff;
				}
			}
			&.-grater {
				background-color: transparent;
				border: 1px solid $primary-blue;
				span {
					color: $primary-blue;
				}
			}
		}

		.bubble-separator {
			width: 1.125rem;
			height: 0px;
			border-top: 2px solid $border-color;
		}
	}
}
