.account-container-mobile {
	@import './UserInfo/userInfoMobile';
	@import './EditarDatos/editarDatosMobile';
	@import './Direcciones/direccionesMobile';
	@import './Mercados/mercadosMobile';
	@import './Tarjetas/tarjetasMobile';
	@import './Historial/historialMobile';

	.account-body {
		width: 100%;
		display: flex;
		gap: 1rem;

		.account-menu {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.account-navigation {
				width: 100%;
				box-sizing: border-box;
				padding: 2.3rem 0;
				display: flex;
				flex-direction: column;
				gap: 1rem;
			}
		}

		.account-component {
			width: 100%;
			color: black;
		}
	}
}
