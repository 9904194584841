// General colors

$primary-blue: #164194;
$primary-blue-transparent: rgba(22, 65, 148, 0.1);
$yellow: #ffe600;

$text-color-light: #858585;
$text-color: #4a4a4a;
$text-color-dark: #212121;
$text-error: #ce6868;

$border-color: #e3e8f0;
$border-color-blue: #3157a0;

$blue-light: #f4f6f9;
$red: #ab0016;
$input-color: #e9e9e9;

// Order status colors

$status-cancelled: #ed4747;
$status-received: #49c9b4;
$status-enlistment: #f7ef57;
$status-payment: #e9b021;
$status-payment-success: #0ed8d5;
$status-payment-error: #c95f38;

$status-dispatched: #9e38c9;
$status-finalized: #d8d8d8;
$status-created: #86c947;
