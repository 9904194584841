.order-status-chip {
	height: 1.375rem;
	border-radius: 10px;
	display: flex;
	justify-content: center;

	font-size: 12px;
	line-height: 22px;
	font-weight: 400;

	padding: 0 0.5rem;
	box-sizing: border-box;

	&.-created {
		color: #fff;
		background-color: $status-created;
	}
	&.-cancelled {
		color: #fff;
		background-color: $status-cancelled;
	}
	&.-received {
		color: #fff;
		background-color: $status-received;
	}
	&.-enlistment {
		color: #454545;
		background-color: $status-enlistment;
	}
  &.-dispatched {
		color: #fff;
		background-color: $status-dispatched;
	}
  &.-payment_success {
		color: #fff;
		background-color: $status-payment-success;
	}
	&.-payment {
		color: #fff;
		background-color: $status-payment;
	}
	&.-finalized {
		color: #454545;
		background-color: $status-finalized;
	}
}
