.offersSliderMobile {
	.offersBar {
		width: 100%;
		margin-top: 2.5em; // 0.5em;
		position: relative;

		.offerCard {
			margin-top: 0.5em;
			margin-right: 0.5em;
			background: #ffffff;
			box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.07);
			border-radius: 20px;
			width: 18.69em;
			height: 8.307em;
			padding: 0;
			overflow: hidden;
			cursor: pointer;
			img {
				width: 100%;
				height: 100%;
			}
			@supports (object-fit: cover) {
				img {
					object-fit: cover;
					object-position: center center;
				}
			}
		}

		.react-horizontal-scrolling-menu--wrapper {
			flex-wrap: wrap;
			justify-content: flex-end;
		}
		//react-horizontal-scrolling-menu items ⬇
		// .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
		// 	display: none;
		// }

		// .react-horizontal-scrolling-menu--scroll-container {
		// 	-ms-overflow-style: none; /* IE and Edge */
		// 	scrollbar-width: none; /* Firefox */
		// 	order: 1;
		// 	flex-basis: 100%;
		// 	flex-shrink: 0;
		// }
		.arrows-container {
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			background: $primary-blue;
			box-shadow: 0px 4px 8px rgba(33, 74, 142, 0.5);
			border-radius: 30px;
			width: 2.5em;
			height: 2.5em;
			margin: -2.5em 0.2em 0.5em;

			&.-disabled {
				background: none;
				box-shadow: none;
				cursor: inherit;
			}

			&.-left {
				margin-right: 3rem;
			}

			&.-right {
				top: 0;
				position: absolute;
			}
		}
	}
}

@media (max-width: 310px) {
	.offersSliderMobile {
		.offersBar {
			.offerCard {
				width: 16.69em;
			}
		}
	}
}
