// genericos
@mixin btn {
	border: 1px solid $primary-blue;
	box-sizing: border-box;
	border-radius: 30px;
	cursor: pointer;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	padding: 10px;
	font-size: 16px;

	transition: background-color 100ms;

	&:focus {
		outline: none;
	}
}

.btn {
	&-filled {
		@include btn;

		&.-revert {
			flex-direction: row-reverse;
		}

		&.-blue {
			background-color: $primary-blue;
			color: #fff;
		}
		&.-white {
			background-color: #fff;
			color: $primary-blue;
		}
		&.-yellow {
			background-color: $yellow;
			color: $primary-blue;
			border-color: $yellow;
		}

		&:disabled {
			opacity: 0.4;
      color: white;
      background-color: $primary-blue;
		}
	}
	&-outlined {
		@include btn;
		background-color: transparent;
		color: $primary-blue;

		&:hover {
			background: $primary-blue-transparent;
		}

		&.-revert {
			flex-direction: row-reverse;
		}
		&.-white {
			border-color: #fff;
			color: #fff;
		}
	}
	&-text {
		@include btn;
		border-color: transparent;
		background-color: transparent;
		color: $primary-blue;

		&:hover {
			background: $primary-blue-transparent;
		}

		&.-revert {
			flex-direction: row-reverse;
		}
		&.-white {
			border-color: #fff;
			color: #fff;
		}
	}
}

// se suele utilizar cuando se ponen precios en el boton

.summary-btn {
	width: 100%;
	height: 40px;

	padding: 0.625rem 1.563rem;
	//margin-bottom: 1.875rem;

	&.-simple {
		justify-content: center;
	}
	&.-double {
		justify-content: space-between;
	}
}

//Botones de pagina de login
.button-primary {
	border: none;
	margin: 8px 0 8px;
	box-shadow: none;
	width: 260px;
	height: 40px;
	border-radius: 30px;
	text-transform: none;
	font-size: 14px;
	line-height: 14px;
	font-style: normal;
	font-weight: 500;
	color: white;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	cursor: pointer;
}

.button-submit {
	background: $primary-blue;
	color: white;
	margin-top: 1em;
}
.button-submit:hover {
	background: #3058a8;
}

.button-google {
	background: #dc4437;
	border-color: #dc4437;
}
.button-google:hover {
	border-color: #dd4d40;
}

.button-facebook {
	background: #4167b2;
}
.button-facebook:hover {
	background: #3058a8;
}

.button-phone {
	background: white;
	border: 1px solid #4a4a4a;
	color: #4a4a4a;
}
.button-phone:hover {
	background: #5a5a5a;
	color: white;
}

.button-yellow {
	background: $yellow;
	color: $primary-blue;
	justify-content: center;
	align-items: center;
	& i {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}
.button-yellow:hover {
	background: #ffeb3a;
}

// boton de logout de la pantalla de mi cuenta
.btn-account-logout {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px 25px 10px 30px;

	border: 1px solid #e3e8f0;
	box-sizing: border-box;
	border-radius: 30px;

	width: 15.31rem;
	height: 3.5rem;
	background-color: $primary-blue;

	cursor: pointer;

	span {
		font-weight: 500;
		color: #fff;
		font-size: 1rem;
	}
}

// boton de opcion no seleccionada del menu de la pantalla de mi cuenta
.btn-account-option {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px 10px 15px;

	height: 3.5rem;
	background-color: $blue-light;

	border: 1px solid #e3e8f0;
	box-sizing: border-box;
	border-radius: 30px;

	cursor: pointer;
	text-decoration: none;

	.option-label {
		display: flex;
		gap: 0.5rem;
		align-items: center;

		span {
			font-weight: 500;
			color: $text-color;
			font-size: 1rem;
		}
	}
}

// boton de opcion seleccionada del menu de la pantalla de mi cuenta
.btn-account-option-selected {
	background-color: $primary-blue;
	border-color: $primary-blue;

	.option-label {
		span {
			color: #fff;
		}
	}
}

//boton vista direcciones
.button-direcciones {
	margin-top: 0em;
	margin-bottom: 2em;
}

.button-delete {
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	i {
		width: 2em;
		height: 2em;
	}
}
