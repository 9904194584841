.direccion-card {
	width: 100%;
	box-sizing: border-box;
	background: #ffffff;
	box-shadow: 0px 5px 20px rgba(33, 74, 142, 0.15);
	border-radius: 10px;

	padding: 1em;
	padding-top: 20px;
	padding: 1.188rem 0.75rem 0.813rem 0.875rem;
	cursor: pointer;

	&:hover {
		background: #e9e9e9;
	}

	.body {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 0.875rem;

		.direccion-title {
			display: flex;
			align-items: center;
			gap: 0.8rem;

			div {
				display: flex;
				flex-direction: column;
			}

			span {
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}
	}
	.card-direccion-footer {
		border-top: 1px solid $border-color;
		display: flex;
		justify-content: space-between;
		padding-top: 0.438rem;
	}
}

.direccion-card--selected {
	background: #e9e9e9 !important;
}

.direccion-card-title-container {
	& h5 {
		margin: 0;
	}
	& .link {
		margin-left: 1.3em;
		font-weight: 600;
		font-size: 12px;
		text-decoration: none;
	}
}
