.cart-direcciones-mobile {
	.direcciones-title {
		margin-bottom: 1.938rem;
		display: block;
	}

	.cart-direccion-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1.063rem;
	}
}
