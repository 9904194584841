.add-product-to-market-mobile {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	.aptm-go_back {
		width: 100%;
	}

	.aptm-title {
		margin-bottom: 1.563rem;
	}

	.aptm-content {
		width: 100%;

		display: flex;
		flex-direction: column;
		align-items: center;
		flex-grow: 1;
		justify-content: space-between;

		.first-segment {
			width: 100%;

			display: flex;
			flex-direction: column;
			align-items: center;
			flex-grow: 1;

			gap: 1.25rem;

			.aptm-products-list {
				width: 100%;
				overflow-y: auto;

				flex-grow: 1;
			}
		}

		.aptm-summary-btn {
			width: 100%;
			margin-top: 1rem;
		}
	}
}
