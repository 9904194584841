.footerDesktop {
	.content {
		margin-top: 3em;
		padding: 1em;
		display: grid;
		grid-template-columns: 1fr 1.3fr 0.8fr;
		.contact {
			display: flex;
			flex-direction: column;
			align-items: center;
			.logo {
				width: 37%;
				img {
					width: 100%;
					height: auto;
				}
			}
			.social-networks {
				display: flex;
				justify-content: center;
				i {
					margin-left: 0.7em;
				}
			}
		}
		.categories {
			padding-left: 3em;
			padding-right: 2em;
			.items {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-template-rows: repeat(5, 1fr);
			}
		}
		.information {
			padding-left: 3em;
			padding-right: 2em;
			.items {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-template-rows: repeat(5, 1fr);
			}
		}
	}
	.year {
		display: flex;
		justify-content: center;
		width: 100%;
	}
}
