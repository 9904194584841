.favorites-pages-desktop {
	min-height: 620px;

	div.favorites-header {
		display: flex;
		justify-content: space-between;

		.favorites-actions {
			width: fit-content;
			display: flex;
			justify-content: space-between;
			margin-bottom: 1.3rem;
			gap: 1rem;
		}
	}

	.products-section {
		width: 100%;
		padding: 0 0.938rem;
		box-sizing: border-box;
		margin-bottom: 3.13rem;
	}

	// sections
	.markets-list {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
	.products-list {
		display: flex;
		flex-direction: column;
		gap: 2.7rem;

		height: 100%;
		overflow-y: auto;
	}
}
