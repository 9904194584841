.payment-method {
	background: #ffffff;
	box-shadow: 0px 5px 20px rgba(33, 74, 142, 0.15);
	border-radius: 10px;
	width: 100%;

	display: flex;
	flex-direction: column;
	padding: 0.861rem 0.978rem;
	box-sizing: border-box;

	&.-selectable {
		cursor: pointer;
	}

	.description {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.action {
			cursor: pointer;
		}

		div:nth-child(1) {
			display: flex;
			align-items: center;
			gap: 0.625rem;

			.info {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
			}
		}
	}

	.shares {
		width: 100%;
		border-top: 1px solid $border-color;
		margin-top: 0.7rem;
		padding-top: 0.438rem;
		display: flex;
		justify-content: space-between;
	}

	.body {
		display: flex;
		justify-content: space-between;
		align-items: center;

		span {
			cursor: pointer;
		}

		.card-title {
			display: flex;
			align-items: center;
			gap: 0.8rem;
		}
	}
}
