.o-login-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.o-login-title-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: $primary-blue;
	margin-top: 1.5rem;
	& p {
		font-style: normal;
		margin-top: 0.5rem;
	}
}

.o-login-form-container {
	display: flex;
	flex-direction: row;
	width: 39em;
	margin-top: 1rem;
	& h4 {
		margin-top: 0;
		line-height: 18px;
	}
}

.o-login-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	& .link {
		margin-top: 1rem;
		cursor: pointer;
	}
}

.o-section-title {
	color: $text-color-light;
	margin-top: 0;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 18px;
}

.o-line {
	background: $text-color-light;
	width: 2px;
	height: 256px;
	opacity: 0.15;
}

.o-login-link-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: $primary-blue;
	font-size: 16px;
	margin: 1.5rem;
	& .link {
		cursor: pointer;
		margin-top: -1em;
	}
}

.login-modal {
	margin: auto;
	width: 50vw !important;
	overflow-y: scroll;
	overflow: auto;
}

@media (max-width: 768px) {
	.o-login-form-container {
		flex-direction: column;
	}

	.o-line {
		width: 256px;
		height: 2px;
		align-self: center;
		margin: 1em;
	}
}
