@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;300;400;500;600;700&display=swap');
@import './variables';
* {
	font-family: 'Lexend', sans-serif;
}

@mixin text($font-size, $font-weight) {
	font-size: $font-size;
	font-weight: $font-weight;
}

@mixin generate() {
	$weights: [0, 100, 300, 400, 500, 600, 700];
	// Loop through all of the sizes
	@for $i from 1 through 36 {
		// Loop through all the weights
		@for $j from 1 through length($weights) {
			$weigth: nth($weights, $j);
			//create class
			.text-#{$i}px-#{$weigth} {
				@include text(#{$i * 0.0625}em, $weigth);
			}
		}
	}
}

@include generate();

.color-white {
	color: #ffffff;
}

.color-blue {
	color: $primary-blue;
}

.color-gray-light {
	color: $text-color-light;
}

.color-gray {
	color: $text-color;
}

.color-gray-dark {
	color: $text-color-dark;
}

.color-black-light {
	color: $text-color;
}

.color-red {
	color: $red;
}

.color-yellow {
	color: $yellow;
}

.color-error {
	color: $text-error;
}

.text-decoration-none {
	text-decoration: none;
}
.text-center {
	text-align: center;
}
.text-nowrap {
	white-space: nowrap;
}
.text-capitalize {
	text-transform: capitalize;
}

.text-cursor-pointer {
	cursor: pointer;
}

.display-none{
	display: none;
}

.bg-yellow {
	background-color: $yellow;
}