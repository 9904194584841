.product-page-mobile {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.name {
		margin-bottom: 1.453rem;
		margin-top: 0.5rem;
	}

	.product-image {
		width: 100%;
		position: relative;

		img.image {
			width: 100%;
		}

		img.mayorista {
			left: 0;
			bottom: 1.35rem;
			width: 4.25rem;
			height: 3.438rem;
			position: absolute;
		}
		.icons {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 1.153rem;

			position: absolute;
			top: 0;
			right: 0;
		}
	}

	.product-type-actions {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		.actions {
			margin-bottom: 2rem;
			display: flex;
			flex-direction: column;
			gap: 1rem;

			.controls {
				width: 100%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				gap: 3rem;

				@media (max-width: 768px) {
					gap: 1rem;
				}
			}
		}
	}

	.details {
		margin-bottom: 2.139rem;
		margin-top: 1.717rem;

		width: 100%;
		display: flex;
		justify-content: space-between;

		div {
			display: flex;
			flex-direction: column;
		}
		div:nth-child(2) {
			align-items: flex-end;
		}
	}

	.maturity {
		box-sizing: border-box;
		width: 100%;
		padding-top: 0;
		padding-bottom: 1.5rem;
	}
}

.related-products {
	width: 100%;

	box-sizing: border-box;
	padding: 0 1rem;

	.products-section {
		& .title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			p {
				text-transform: capitalize;
			}
		}
	}
}
