.city-selector-desktop {
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 26.063rem;
	box-sizing: border-box;
	padding: 6rem 4.406rem;

	img.logo {
		width: 17.277em;
		margin-bottom: 3rem;
		@media screen and (max-width: 768px) {
			width: 100%;
		}
	}

	.selector-message {
		text-align: center;
		display: block;
		margin-bottom: 2.938rem;
		margin-top: 1.319rem;
	}

	.selector-cities {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2rem;
	}
}
