.order-finished-mobile {
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;

	box-sizing: border-box;

	.title {
		margin-bottom: 4.7rem;
	}

	.order-image {
		position: relative;
		margin-bottom: 2.014rem;

		img:nth-child(2) {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 0);
		}
	}

	.message {
		width: 19.4rem;
		text-align: center;
		margin-bottom: 2.361rem;
		.mi-cuenta {
			text-decoration: none;
		}
	}
}
