.homeMobile {
	.categories {
		.card {
			margin: 1em 0.5em;
			background: #ffffff;
			box-shadow: 0px 0px 0.9em rgba(33, 74, 142, 0.3);
			display: grid;
			grid-template-rows: 2fr 1fr;
			padding: 0.15em;
			height: 5em;
			width: 5em;
			border-radius: 0.5em;
			.card-image {
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: 2em;
					height: auto;
				}
			}
		}
	}
	.category-section {
		& .title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			p {
				text-transform: capitalize;
			}
		}
	}

	//react-horizontal-scrolling-menu items ⬇
	// .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
	// 	display: none;
	// }

	// .react-horizontal-scrolling-menu--scroll-container {
	// 	-ms-overflow-style: none; /* IE and Edge */
	// 	scrollbar-width: none; /* Firefox */
	// }
}
