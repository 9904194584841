.footerMobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 3em;
	background: #f4f6f9;
	padding-bottom: 75px;
	.go-up {
		margin-top: -1.2em;
		margin-right: 1.5em;
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		justify-content: flex-end;
		width: 100%;
		.icon-container {
			background: $primary-blue;
			padding: 0.6em;
			border-radius: 0.5em;
		}
	}
	.logo {
		margin-top: 1em;
		display: flex;
		justify-content: center;
		margin-bottom: 1em;
		img {
			width: 70%;
			height: auto;
		}
	}
	.information-item {
		margin-bottom: 0.5em;
		color: #4a4a4a;
	}
	.social-networks {
		display: flex;
		justify-content: center;
		margin-bottom: 1em;
		i {
			margin-left: 0.7em;
		}
	}
	.navbar {
		width: 95vw;
		height: 4rem;
		border-radius: 2em;
		background: #ffffff;
		position: fixed;
		bottom: 1em;
		display: grid;
		box-shadow: 0px 0px 0.9em rgba(33, 74, 142, 0.3);

		.content {
			display: grid;
			grid-template-columns: repeat(5, 1fr);

			.item {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 0.2rem;
			}
			.cart {
				background: $primary-blue;
				border-radius: 2em;
				display: flex;
				justify-content: center;
				align-items: center;
				& .item-counter {
					background: $yellow;
					position: absolute;
					padding: 0.3em;
					color: $primary-blue;
					border-radius: 0.170625em;
					margin-bottom: 3em;
					margin-left: 1.5em;
				}
			}
		}
	}
}
