.add-product-to-market-desktop {
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 30rem;

	.aptm-title {
		margin-bottom: 1.563rem;
	}

	.aptm-content {
		width: 100%;

		display: flex;
		flex-direction: column;
		align-items: center;

		.first-segment {
			width: 100%;

			display: flex;
			flex-direction: column;
			align-items: center;

			gap: 1.25rem;
		}

		.aptm-products-list {
			width: 100%;
			max-height: 30rem;
			overflow-y: auto;

			padding-right: 0.5rem;
			box-sizing: border-box;
		}
	}

	.aptm-summary-btn {
		width: 100%;
		margin-top: 1rem;
	}
}
