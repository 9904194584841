.tarjetas-desktop {
	height: 100%;
	display: flex;
	flex-direction: column;

	.content {
		box-sizing: border-box;
		flex-grow: 1;

		.cards-container {
			display: flex;
			flex-direction: column;
			gap: 1.188rem;
		}

		.cards-form {
			height: 100%;
		}
	}
}
