.tarjetas-mobile {
	.title {
		margin-bottom: 1.343rem;
		display: block;
	}

	.content {
		box-sizing: border-box;
		padding-top: 0.527rem;

		.cards-container {
			display: flex;
			flex-direction: column;
			gap: 1.188rem;
		}
	}
}
