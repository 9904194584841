.loading-screen {
	background-color: rgba(255, 255, 255, 0.4);

	height: 100%;
	width: 100%;

	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}
