.homeDesktop {
	.category-section {
		margin-left: 1em;
		margin-right: 1em;
		& .title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			p {
				text-transform: capitalize;
			}
		}
	}
	//react-horizontal-scrolling-menu items ⬇
	// .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
	// 	display: none;
	// }

	// .react-horizontal-scrolling-menu--scroll-container {
	// 	-ms-overflow-style: none; /* IE and Edge */
	// 	scrollbar-width: none; /* Firefox */
	// }
	.arrows-container {
		display: flex;
		align-items: center;
		cursor: pointer;
		&.-disabled {
			display: none;
		}
	}
}
