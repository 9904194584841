.cart-resumen-mobile {
	.resumen-title {
		display: block;
		margin-bottom: 2.125rem;
	}

	.selected-payment-method {
		margin-bottom: 2.318rem;
		margin-top: 2.275rem;

		display: flex;
		flex-direction: column;
		gap: 0.768rem;
	}

	.summary-section {
		margin-bottom: 2.318rem;
		margin-top: 0rem;

		display: flex;
		flex-direction: column;
		gap: 0.711rem;
	}
}

@import './floatingFinishOrder';
