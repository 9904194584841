.favorites-pages-mobile {
	.favorites-actions {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin: 2.063rem 0;
		margin-bottom: 1.3rem;
	}

	.markets-list {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
}
