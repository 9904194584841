.o-text-container-resetpassword {
	width: 278px;
	text-align: center;
	margin-bottom: 1rem;
}

.o-reset-link-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #164194;
	font-size: 16px;
	& .link {
		margin-bottom: 2rem;
		cursor: pointer;
	}
}

.reset-modal {
	width: 30vw;
	margin-bottom: 2rem;
}
