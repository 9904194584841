@mixin fadeIn($duration) {
	animation-name: fadeIn;
	animation-duration: $duration;

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
}

.fade-in {
	@include fadeIn(250ms);
}
