.appBarDesktop {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: sticky;
	top: 0;
	z-index: 3;

	.topBar {
		background: #f4f6f9;
		max-width: 100vw;
		height: 7.5em;
		display: grid;
		grid-template-columns: 1fr 2fr 1fr 4fr;
		gap: 1em;
		padding: 1em;
		margin-top: -1.5em;

		& .logo {
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 90%;
				height: auto;
			}
		}

		// search input
		@import './searchInputDesktop';

		& .select {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		& .menu {
			display: flex;
			align-items: center;

			.actions {
				cursor: pointer;
				height: 3.5em;
				background: $primary-blue;
				//padding: 1em;
				width: 100%;
				border-radius: 2em;
				display: grid;
				grid-template-columns: 1.5fr 0.1fr 1fr 0.1fr 1fr 1fr;
				color: #ffffff;

				.divider {
					display: flex;
					align-items: center;

					hr {
						background: #375ca3;
						border: none;
						width: 0.1rem;
						height: 1em;
					}
				}
				.address {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					margin-left: 1em;

					i {
						margin-right: 0.5em;
						width: 4em;
					}

					span {
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}
				}
				.favorites {
					display: flex;
					align-items: center;
					justify-content: center;

					i {
						margin-right: 0.5em;
					}
				}
				.account {
					display: flex;
					align-items: center;
					justify-content: center;

					i {
						margin-left: 0.5em;
					}
					span {
						color: #ffffff;
						text-decoration: none;
						display: flex;
						align-items: center;
					}
				}
				.cart {
					cursor: pointer;
					background-color: #ffffff;
					width: 101%;
					height: 100%;
					border-radius: 2em;
					display: grid;
					grid-template-columns: 1fr 1.5fr;

					& .icon {
						display: flex;
						justify-content: center;
						align-items: center;

						& .item-counter {
							background: $red;
							position: absolute;
							padding: 0.3em;
							border-radius: 0.170625em;
							margin-bottom: 3em;
							margin-left: 1.5em;
						}
					}
					& .total {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: center;
					}
				}
			}
		}
	}
	.categoriesBar {
		.skeletons {
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			margin-top: -0.25em;
		}

		overflow: hidden;

		margin-top: -1.5em;
		padding-top: 0.5em;
		background: #ffffff;

		width: 98%;
		height: 3em;

		z-index: 2;

		border-radius: 2em;
		box-shadow: 4.19689px 11.1917px 27.9793px rgba(74, 74, 74, 0.18);

		.categories-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 0.5em;

			box-sizing: border-box;
			padding: 0.438em 0em;
			margin: 0.2em 0.7em;
			border-radius: 1.875em;
			transition: all 300ms ease-in-out;

			& img {
				width: 1.4em;
				height: 1.4em;
			}

			&:hover {
				background: $primary-blue !important;
				padding: 0.438em 0.838em;

				& > span {
					color: #ffffff;
				}

				& img {
					filter: brightness(0) invert(1);
				}
			}

			&.-selected {
				background: $primary-blue;
				padding: 0.438em 0.838em;

				& img {
					filter: brightness(0) invert(1);
				}
			}

			&.-unselected {
				background: #fff;
			}

			.text {
				margin-left: 0.5em;
			}
		}
		//react-horizontal-scrolling-menu items ⬇
		// .react-horizontal-scrolling-menu--wrapper{
		//   margin-top: -1.5em;
		//   background: #ffffff;
		//   width: 98%;
		//   height: 3em;
		//   z-index: 2;
		//   border-radius: 2em;
		//   box-shadow: 4.19689px 11.1917px 27.9793px rgba(74, 74, 74, 0.18);
		// }
		// .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
		// 	display: none;
		// }

		// .react-horizontal-scrolling-menu--scroll-container {
		// 	-ms-overflow-style: none; /* IE and Edge */
		// 	scrollbar-width: none; /* Firefox */
		// }
		.arrows-container {
			width: 1.68rem;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			width: 1.5rem;
			&.-disabled {
				display: none;
			}
		}
	}
}

.subMenuList {
	display: none;
	width: 12em;
	max-height: 13em;
	position: absolute;
	background: white;
	overflow-y: auto;
	overflow-x: hidden;
	margin-top: -2px;
	margin-left: 5px;
	box-shadow: 0.3em 0.8em 1em rgb(74 74 74 / 18%);
	z-index: 3;
	border-radius: 0em 0em 1em 1em;
}

.categoriesShowSubmenu {
	&:hover {
		.subMenuList {
			display: flex;

			&::-webkit-scrollbar {
				width: 3px;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				margin-top: 10px;
				background: #6c6c6c70;
				border-radius: 22px;
			}
			// &:hover{
			//   background:grey;
			//   color: white;
			// }
			ul {
				width: 100%;
				padding: 0;
				margin: 0;
				a {
					text-decoration: none;
					color: #585858;
				}
			}
			li {
				font-weight: 500;
				font-size: 14px;
				padding: 0.5em;
				list-style: none;
				p {
					margin: 7px 0 7px 9px;
				}
			}
			li:hover {
				background: #d5d5d5;
				cursor: pointer;
			}
		}
	}
}

// .react-horizontal-scrolling-menu--scroll-container {
// 	position: unset !important;
// }
