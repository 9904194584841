.custom-scrollbar {
	// scroll bar
	&::-webkit-scrollbar {
		width: 6px;
		margin-right: -10px;
	}
	// scroll bar-firefox
	scrollbar-color: $primary-blue $input-color;
	scrollbar-width: thin;
	/* Track */
	&::-webkit-scrollbar-track {
		width: 6px;
		background: $input-color;
	}
	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: $primary-blue;
	}
}
