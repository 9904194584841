.order-card {
	background-color: #ffffff;
	box-shadow: 0px 5px 20px rgba(33, 74, 142, 0.15);
	border-radius: 10px;

	box-sizing: border-box;
	padding: 0.938rem;
	cursor: pointer;

	text-decoration: none;

	&.-selected {
		background: $primary-blue;
	}

	&.-unselected {
		background: #ffffff;
	}

	.order-card-title {
		display: flex;
		justify-content: space-between;
		&.-selected {
			border-bottom: 1px solid $border-color-blue;
		}
		&.-unselected {
			border-bottom: 1px solid $border-color;
		}
		padding-bottom: 0.5rem;
	}

	.details {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding-top: 0.5rem;

		div {
			display: flex;
			flex-direction: column;
			gap: 0.438rem;
		}
		div:nth-child(2) {
			align-items: flex-end;
		}
	}
}
