.search-mobile {
	padding: 0.5em;
	background: linear-gradient(
		to bottom,
		#f4f6f9 0%,
		#f4f6f9 50%,
		transparent 51%,
		transparent 100%
	);
	margin-bottom: 0.5em;
	.react-autosuggest__container {
		position: relative;
		width: 100%;
		height: 3.125rem;

		.react-autosuggest__suggestions-container--open {
			display: block;
			box-sizing: border-box;
			background-color: #fff;
			z-index: 99;
			border-radius: 5px;
			box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
			height: 50vh;
			overflow-y: scroll;
		}

		.react-autosuggest__suggestions-list {
			margin: 0;
			padding: 0;
			list-style-type: none;

			& ~ .search-more {
				display: flex;
			}
		}

		.search-more {
			display: none;
			border-top: 1px solid $border-color;
			justify-content: center;
			padding: 0.8rem;

			cursor: pointer;
			border-radius: 5px;
			overflow: hidden;

			&:hover {
				background-color: $blue-light;
			}

			span {
				width: 100%;
				max-width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: center;
				strong {
					color: $primary-blue;
				}
			}
		}
	}

	#react-autowhatever-1 {
		position: absolute;
		width: 100%;
		top: 3.125rem;
		margin-top: 0.8rem;
	}
}
