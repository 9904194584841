.searchResultDesktop {
	.category-section {
		margin-left: 1em;
		margin-right: 1em;
		& .title {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.containerProducts {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		gap: 1em;
	}

	.circularProgress {
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 1em;
	}
}
