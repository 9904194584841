.mercado-card {
	&.-selected {
		background-color: $primary-blue;
	}
	&.-unselected {
		background-color: #fff;
	}

	box-sizing: border-box;

	display: flex;
	flex-direction: row;

	padding: 0.938rem;

	justify-content: space-between;

	box-shadow: 0px 5px 20px rgba(33, 74, 142, 0.15);
	border-radius: 10px;

	cursor: pointer;
	text-decoration: none;

	.card-info {
		flex-grow: 1;

		.title {
			display: block;
			margin-bottom: 8px;
		}

		.body {
			&.-selected {
				border-top: 1px solid $border-color-blue;
			}
			&.-unselected {
				border-top: 1px solid $border-color;
			}

			box-sizing: border-box;
			padding-top: 8px;
			margin-right: 21px;

			display: flex;
			align-items: flex-end;
			justify-content: space-between;

			div {
				display: flex;
				flex-direction: column;
				gap: 7px;
			}
		}
	}

	.card-actions {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 100%;

		i {
			cursor: pointer;
		}
	}
}
