@mixin icon {
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	display: inline-block;
}

@mixin account-option-icon {
	height: 1.25em;
	width: 1.25em;
	@include icon;
}

.cursor-pointer {
	cursor: pointer;
}

.search-icon {
	background: url('../../../assets/icons/search.svg');
	height: 1em;
	width: 1em;
	@include icon;
}

.google-icon {
	background: url('../../../assets/icons/google.svg');
	height: 1.5em;
	width: 1.5em;
	@include icon;
}

.facebook-icon {
	background: url('../../../assets/icons/facebook.svg');
	height: 1.5em;
	width: 1.5em;
	&-blue {
		background: url('../../../assets/icons/facebook-blue.svg');
		@include icon;
		height: 1.2em;
		width: 1.2em;
	}
	@include icon;
}

.instagram-icon {
	background: url('../../../assets/icons/instagram-white.svg');
	height: 1.2em;
	width: 1.2em;
	@include icon;
}

.whatsapp-icon {
	background: url('../../../assets/icons/whatsapp-white.svg');
	height: 1.2em;
	width: 1.2em;
	@include icon;
}

.qr-code-icon {
	background: url('../../../assets/icons/qr-code-icon.svg');
	height: 1.2em;
	width: 1.2em;
	@include icon;
}

.phone-icon {
	background: url('../../../assets/icons/phone.svg');
	height: 1.5em;
	width: 1.5em;
	@include icon;
}

.arrow {
	height: 1em;
	width: 1em;
	&.-blue {
		background: url('../../../assets/icons/arrow_blue.svg');
		@include icon;
		&.-down {
			transform: rotate(90deg);
		}
		&.-left {
			transform: rotate(180deg);
		}
		&.-up {
			transform: rotate(270deg);
		}
	}
	&.-white {
		background: url('../../../assets/icons/arrow_white.svg');
		@include icon;
		&.-up {
			transform: rotate(270deg);
		}
		&.-left {
			transform: rotate(180deg);
		}
		&.-down {
			transform: rotate(90deg);
		}
	}
	&.-red {
		background: url('../../../assets/icons/arrow_red.svg');
		@include icon;
	}
}

.cart-icon {
	height: 2em;
	width: 2em;

	&.-white {
		background: url('../../../assets/icons/cart-white-outlined.svg');
		@include icon;

		&.-menu {
			height: 17px;
			width: 17px;
		}
	}
	&.-blue {
		background: url('../../../assets/icons/cart-outlined.svg');
		@include icon;
	}
}

.cart-icon-2 {
	height: 1em;
	width: 1em;

	&.-white {
		background: url('../../../assets/icons/cart-2-white.svg');
		@include icon;
	}
	&.-small {
		width: 0.8em;
		height: 0.8em;
	}
}

.edit-data-icon {
	height: 1.5em;
	width: 1.5em;
	background: url('../../../assets/icons/edit_data_blue.svg');
	@include icon;
}

.historial-icon {
	&.-black {
		background: url('../../../assets/icons/historial_black.svg');
		@include account-option-icon;
	}
	&.-white {
		background: url('../../../assets/icons/historial_white.svg');
		@include account-option-icon;
	}
}

.location-2-icon {
	&.-black {
		background: url('../../../assets/icons/location_black.svg');
		@include account-option-icon;
	}
	&.-white {
		background: url('../../../assets/icons/location_white.svg');
		@include account-option-icon;
	}
}

.card-icon {
	&.-black {
		background: url('../../../assets/icons/card_black.svg');
		@include account-option-icon;
	}
	&.-white {
		background: url('../../../assets/icons/card_white.svg');
		@include account-option-icon;
	}
}

.food-1-icon {
	&.-black {
		background: url('../../../assets/icons/food_1_black.svg');
		@include account-option-icon;
	}
	&.-white {
		background: url('../../../assets/icons/food_1_white.svg');
		@include account-option-icon;
	}
}

.location-icon {
	height: 1.2em;
	width: 1.2em;
	&.-white {
		background: url('../../../assets/icons/location.svg');
		@include icon;
	}
	&.-blue {
		background: url('../../../assets/icons/location-blue.svg');
		@include icon;
	}
}

.heart {
	height: 1.2em;
	width: 1.2em;
	&.-white-outlined {
		background: url('../../../assets/icons/hearth-white-outlined.svg');
		@include icon;
	}
	&.-gray-outlined {
		background: url('../../../assets/icons/heart-gray-outlined.svg');
		@include icon;
	}
	&.-blue-outlined {
		background: url('../../../assets/icons/heart-blue-outlined.svg');
		@include icon;
	}
}

.heart-icon-circle {
	height: 2.232em;
	width: 2.232em;
	&.-checked {
		background: url('../../../assets/icons/heart-blue-circle-checked.svg');
		@include icon;
	}
	&.-unchecked {
		background: url('../../../assets/icons/heart-blue-circle-unchecked.svg');
		@include icon;
	}
	&.-small {
		height: 1.563em;
		width: 1.563em;
	}
}
.market-icon-circle {
	height: 2.232em;
	width: 2.232em;
	background: url('../../../assets/icons/market-icon-circle.svg');
	@include icon;
	&.-small {
		height: 1.563em;
		width: 1.563em;
	}
}

.heart-2 {
	height: 2.5em;
	width: 2.5em;
	&.-checked {
		background: url('../../../assets/icons/heart-contained-checked.svg');
		@include icon;
	}
	&.-unchecked {
		background: url('../../../assets/icons/heart-contained-unchecked.svg');
		@include icon;
	}
	&.-small {
		width: 1.8em;
		height: 1.8em;
	}
}

.load-icon {
	height: 2.5em;
	width: 2.5em;
	background: url('../../../assets/icons/load.svg');
	@include icon;
	&.-small {
		width: 1.8em;
		height: 1.8em;
	}
}

.person {
	height: 1.2em;
	width: 1.2em;
	&.-white-outlined {
		background: url('../../../assets/icons/person-white-outlined.svg');
		@include icon;
	}
	&.-gray-outlined {
		height: 1.1em;
		width: 1.1em;
		background: url('../../../assets/icons/person-gray-outlined.svg');
		@include icon;
	}
	&.-blue-outlined {
		height: 1.1em;
		width: 1.1em;
		background: url('../../../assets/icons/person-blue-outlined.svg');
		@include icon;
	}
}

.edit-icon {
	height: 1.563em;
	width: 1.563em;
	&.-blue {
		background: url('../../../assets/icons/edit_data_blue.svg');
		@include icon;
	}
	&.-white {
		background: url('../../../assets/icons/edit_data_white.svg');
		@include icon;
	}
}

.delete-icon-circle {
	height: 1.563em;
	width: 1.563em;
	&.-blue {
		background: url('../../../assets/icons/delete_circle_blue.svg');
		@include icon;
	}
	&.-white {
		background: url('../../../assets/icons/delete_circle_white.svg');
		@include icon;
	}
}

.home-icon {
	&.-gray {
		height: 1.1em;
		width: 1.4em;
		background: url('../../../assets/icons/home-gray.svg');
		@include icon;
	}
	&.-blue {
		height: 1.1em;
		width: 1.4em;
		background: url('../../../assets/icons/home-blue.svg');
		@include icon;
	}
}

.wallet-icon {
	&.-gray-outlined {
		height: 1.1em;
		width: 1.4em;
		background: url('../../../assets/icons/wallet-gray-outlined.svg');
	}

	&.-blue-outlined {
		height: 1.1em;
		width: 1.4em;
		background: url('../../../assets/icons/wallet-blue-outlined.svg');
		@include icon;
	}
}
.delete-icon {
	height: 0.813rem;
	width: 0.813rem;
	&.-white {
		background: url('../../../assets/icons/delete_white.svg');
		@include icon;
	}
}

/* iconos de metodos de pago */
.pse-icon {
	height: 1.563em;
	width: 1.563em;
	background: url('../../../assets/icons/pse.png');
	@include icon;
}
.cash-icon {
	height: 1.563em;
	width: 1.563em;
	background: url('../../../assets/icons/money.svg');
	@include icon;
}
.mastercard-icon {
	height: 2.063em;
	width: 2.625em;
	background: url('../../../assets/icons/mastercard.svg');
	@include icon;
}
.visa-icon {
	height: 1em;
	width: 3.125em;
	background: url('../../../assets/icons/visa.svg');
	@include icon;
}

.round-check-icon {
	height: 1.375em;
	width: 1.375em;

	&.-checked {
		background: url('../../../assets/icons/round_check_checked.svg');
		@include icon;
	}
	&.-unchecked {
		background: url('../../../assets/icons/round_check_unchecked.svg');
		@include icon;
	}
}

.x-icon {
	height: 1em;
	width: 1em;
	background: url('../../../assets/icons/x-blue-outlined.svg');
	@include icon;
}

.fuits {
	height: 1em;
	width: 1em;
	background: url('../../../assets/icons/fruits.svg');
	@include icon;
}
