.create-card {
	.icon {
		display: flex;
		justify-content: center;
		svg {
			width: 3rem;
			height: 2rem;
		}
	}

	.btn-container {
		display: flex;
		justify-content: space-around;
		button {
			margin-left: 0.5em;
			margin-right: 0.5em;
		}
	}
}
