@import './../../../../styles/sass/base/variables';
.Search {
	position: relative;
}

.Search__suggest_container {
	border-radius: 0rem 1rem 1rem 1rem !important;
	max-width: 100vw !important;
	background-color: white !important;
	box-shadow: 0px 19px 30px rgba(15, 34, 67, 0.28) !important;
	
	&::-webkit-scrollbar {
		width: 0.4rem;
	}

	&__internal {
		@media screen and (max-height: 630px) {
			height: 100vh;
			max-height: calc(100vh - 141px);
		}
	}
}

.Search__suggest_container::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
.Search__suggest_container::-webkit-scrollbar-thumb {
	background-color: rgb(200, 200, 200);
	border-radius: 6px;
}
.Search__suggest_container__resultszero {
	padding: 1rem;
	color: gray;
}
.Search__suggest_container__viewmore {
	background-color: $primary-blue;
	padding: 1rem 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $blue-light;
	border: 0;
	width: 100%;
	cursor: pointer;
	gap: 0.5rem;
}
.search__suggestion_mobile {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	padding: 1rem;
	border-top: 1px solid $border-color;
	cursor: pointer;

	.image-container {
		max-height: 4rem;
		max-width: 4rem;
		min-width: 4rem;
		overflow: hidden;
		border-radius: 1rem;
		width: 4rem;
		height: 4rem;

		img {
			height: 4rem;
		}

		.image {
			height: 4rem;
		}
	}

	.info {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		.name {
			margin-bottom: 0.5rem;
		}
	}

	&:hover {
		background-color: $blue-light;
	}
}

.search__suggestion {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	padding: 1rem;
	border-top: 1px solid $border-color;
	cursor: pointer;
	gap: 0.5rem;
	min-width: 20rem;
	.image-container {
		max-height: 4rem;
		max-width: 4rem;
		min-width: 4rem;
		overflow: hidden;
		border-radius: 1rem;
		width: 4rem;
		height: 4rem;
		img {
			height: 4rem;
		}

		.image {
			height: 4rem;
		}
	}

	.info {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		.name {
			margin-bottom: 0.5rem;
		}
	}

	&:hover {
		background-color: $blue-light;
	}
}
