.address-coverage-modal {
	width: 40vw;
	height: 60vh;
	margin-top: 1em;

	.btn-modal-content {
		margin-top: 1em;
		margin-bottom: 1em;
		display: flex;
		flex-direction: row;
		justify-content: center;

		.btn-modal {
			margin-right: 1em;
			margin-left: 1em;
		}
	}

	p.success-message {
		//ESTILOS PROVISIONALES
		color: #86c947;
	}

	p.error-message {
		//ESTILOS PROVISIONALES
		color: #ce6868;
	}
}

.address-coverage-modal-mobile {
	width: 100%;
	height: 100%;
	margin-top: 1em;

	.btn-modal-content {
		margin-top: 1em;
		display: flex;
		flex-direction: row;
		justify-content: center;

		.btn-modal {
			margin-right: 1em;
			margin-left: 1em;
		}
	}

	p.success-message {
		//ESTILOS PROVISIONALES
		color: #86c947;
	}

	p.error-message {
		//ESTILOS PROVISIONALES
		color: #ce6868;
	}
}
