.select-address-modal {
	min-width: 16em;
	min-height: 13em;

	.direcciones-title {
		margin-bottom: 0.938rem;
		display: block;
		text-align: center;
	}

	.cart-direccion-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1.063rem;
		max-height: 25em;
		overflow-y: auto;
		padding: 0.5em;
	}

	.btn-modal {
		margin-top: 1em;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
