.user-info {
	width: 100%;
	display: grid;
	grid-template-columns: 45% 55%;
	grid-template-rows: min-content max-content;

	.user-name {
		font-size: 1rem;
		color: $text-color;
		font-weight: 500;
	}

	.user-header {
		grid-column-start: 1;
		grid-column-end: 3;
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;

		.edit-user-data {
			display: flex;
			align-items: center;
			gap: 1rem;
			cursor: pointer;
			span {
				color: $primary-blue;
				font-weight: 500;
			}
		}
	}
}

.user-data {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
}

.userfield {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	color: $text-color;

	.userfield-label {
		font-size: 0.625rem;
		line-height: 8px;
		font-weight: normal;
	}
	.userfield-value {
		font-size: 1rem;
		line-height: 18px;
		font-weight: 500;
		word-break: break-word;
	}
}
