.user-photo {
	width: 108px;
	height: 108px;
	overflow: hidden;
	border-radius: 50%;
	position: relative;

	input[type='file'] {
		display: none;
	}

	.imageContainer {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		// z-index: 1;
		// border-radius: 50%;
	}

	.edit-photo {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		display: none;
	}

	.progress-indicator {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) !important;
		z-index: 2;
		display: block;
	}

	&:hover {
		cursor: pointer;
		img {
			filter: brightness(70%);
		}
		.edit-photo {
			display: block;
		}
	}

	&.loading {
		cursor: auto;
		img {
			filter: brightness(70%);
		}
		.progress-indicator {
			display: block;
		}
	}
}
