.order-summary {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	background: #ffffff;
	box-shadow: 0px 5px 20px rgba(33, 74, 142, 0.15);
	border-radius: 10px;
	padding: 2.375rem 0.938rem 1.875rem 0.938rem;

	.title {
		margin-bottom: 1.375rem;
	}

	.order-summary-section {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;

		padding-bottom: 1.875rem;

		&.-with-border {
			border-bottom: 1px solid $border-color;
		}

		.content {
			box-sizing: border-box;
			width: 100%;

			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: 0.5rem;

			div.price,
			div.description {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
			div.price {
				align-items: flex-end;
			}
		}

		.message {
			margin-top: 0.5rem;
		}

		.discounts-list {
			margin: 0.3rem 0;
			padding-left: 0rem;
			list-style: none;
		}
	}
}
