.product-page-desktop {
	.product-card {
		width: 90%;
		height: 100%;
		min-height: 31.5rem;

		background: #ffffff;
		box-shadow: 4.19689px 11.1917px 27.9793px rgba(74, 74, 74, 0.18);
		border-radius: 25px;

		box-sizing: border-box;
		padding: 1.849rem;

		@media (min-width: 1400px) {
			width: 80%;
			min-height: 30.75rem;
		}

		.image-container {
			padding-left: 2rem;
			padding-right: 1rem;
			box-sizing: border-box;

			img.product-image {
				width: 100%;
				height: auto;
			}
		}

		.body {
			display: grid;
			grid-template-columns: 80% 20%;

			box-sizing: border-box;
			padding-bottom: 1.7rem;

			border-bottom: 1px solid $border-color;

			i {
				justify-self: end;
			}

			img.goBack {
				cursor: pointer;
			}

			div:nth-child(1) {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				gap: 1.11rem;
				width: 100%;

				div:nth-of-type(1) {
					width: 100%;
				}

				.details {
					display: grid;
					grid-template-rows: 1fr 1fr;
					grid-template-columns: 1fr 1fr;

					.align-center,
					img {
						align-self: center;
					}
					img {
						justify-self: flex-end;
					}
					div {
						display: flex;
						flex-direction: column;
					}
					div:nth-child(4) {
						text-align: end;
					}
				}
			}
		}

		.maturity {
			box-sizing: border-box;
			width: 100%;
			padding: 1.7rem 0;
			padding-bottom: 0;
			margin: 0.5rem 0;
		}

		.product-type-actions {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			gap: 1rem;

			.actions {
				padding-top: 2rem;
				display: flex;
				flex-direction: column;
				gap: 1rem;

				.controls {
					display: flex;
					align-items: center;

					flex-wrap: wrap;
					column-gap: 1.214rem;
					row-gap: 1rem;
				}
			}
		}

		.description {
			padding-top: 2.625rem;
		}
	}
}

.related-products {
	width: 100%;

	box-sizing: border-box;
	padding: 0 1rem;

	.products-section {
		& .title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			p {
				text-transform: capitalize;
			}
		}
	}
}

.btm-back {
	display: flex;
	align-items: center;
	cursor: pointer;

	span {
		margin-left: 4px;
		color: #164194;
	}
}

.container-favorite-market {
	height: 6em;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(2, 3em);
}
