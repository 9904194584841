.o-login-form-container-mobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 1rem;
	& h4 {
		color: $text-color-light;
		margin-top: 0;
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 18px;
	}
}

.o-login-form-mobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	& .link {
		font-weight: 300;
		font-size: 16px;
		line-height: 18px;
		color: $primary-blue;
		text-decoration: none;
		margin-top: 1rem;
	}
}

.o-login-link-container-mobile {
	margin: 0.5rem;
}
