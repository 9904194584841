.historial-desktop {
	height: 100%;
	display: flex;
	flex-direction: column;

	.title {
		display: flex;
		margin-bottom: 1.786rem;
	}

	.principal-container {
		flex-grow: 1;
		overflow: hidden;

		@media screen and (max-width: 1280px) {
			overflow-y: auto;
		}

		.order-details {
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			gap: 1.4rem;

			height: 100%;
			overflow-y: auto;

			@media screen and (max-width: 1280px) {
				height: fit-content;
			}

			.historial-section {
				display: flex;
				flex-direction: column;
				gap: 1.25rem;
			}
		}

		.order-container {
			display: flex;
			flex-direction: column;
			gap: 0.875rem;

			height: 100%;
			overflow-y: auto;
			@media screen and (max-width: 1280px) {
				height: fit-content;
			}
		}
	}
}
