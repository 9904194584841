.o-line-horizontal {
	background: $text-color-light;
	width: 287.94px;
	height: 2px;
	opacity: 0.15;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.register-modal {
	width: 30vw;
	height: 90vh;
	margin: auto;
}

.o-register-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 3em;
}

.o-register-title-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: $primary-blue;
	margin-top: 2em;
	& p {
		font-style: normal;
		margin-top: 2rem;
	}
}

.o-register-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 16.25rem;
	gap: 0.5rem;
	& .link {
		margin-top: 1rem;
		cursor: pointer;
	}
	.MuiBox-root {
		margin-bottom: 0;
	}
}
