.appBarMobile {
	filter: drop-shadow(2px 4px 6px #e5e5e5);
	position: sticky;
	top: 0;
	z-index: 2;

	.logo-and-sections {
		background: #f4f6f9;
		//width: 100%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		padding: 0.5em;

		.logo {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 80%;
				height: auto;
			}
		}
		.sections {
			padding: 0.5em;
		}
	}

	.logo-navigation {
		width: 100%;
		height: 80px;
		background: #f4f6f9;
		padding: 0.5rem;
		box-sizing: border-box;

		div {
			width: 100%;
			height: 100%;
			position: relative;

			.back-arrow {
				position: absolute;
				left: 0;
				top: 50%;
				transform: translate(0, -50%);
			}
			.logo {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				img {
					width: 90%;
					height: auto;
				}
			}
		}

		/* .logo {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 60%;
                height: auto;
            }
        } */
	}

	.appbar-address {
		width: 100%;
		box-sizing: border-box;
		padding: 0 1rem;
		padding-bottom: 0.5rem;

		background: #f4f6f9;

		div {
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				text-align: center;
			}

			.selected-address {
				margin-left: 0.5rem;
				margin-right: 0.313rem;
				width: 75%;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
	@import './searchInputMobile';
}
