.producto-mayorista {
	background-color: #fff;
	box-sizing: border-box;
	overflow: hidden;
	height: 7.7rem;

	display: grid;
	grid-template-columns: 28% 72%;
	grid-template-rows: 100%;
	align-items: flex-start;
	padding: 11px 11px 11px 5px;

	box-shadow: 0px 5px 20px rgba(33, 74, 142, 0.15);
	border-radius: 10px;

	.product-img {
		position: relative;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			max-height: 100%;
			box-sizing: border-box;
			padding-right: 0.3rem;
		}
		.mayorista-logo {
			position: absolute;
			left: 0;
			top: 0;
			width: 2.5rem;
		}
	}

	.product-data {
		flex-grow: 1;

		.product-info {
			.product-prices {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				margin-top: 7px;
				padding-bottom: 7px;
				border-bottom: 1px solid $border-color;

				.price {
					display: flex;
					flex-direction: column;
				}
			}
		}
		.product-actions {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			padding-top: 7px;

			i {
				cursor: pointer;
			}
		}
	}
}
