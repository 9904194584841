.section-header {
	display: flex;
	align-items: center;
	gap: 1.2rem;
	margin-bottom: 2rem;
	text-transform: capitalize;
	img {
		cursor: pointer;
	}
}
