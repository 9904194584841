.compareProductModal {
	& .substitute-list {
		box-sizing: border-box;
		padding: 5%;
		width: 100%;
		height: 50vh;
		overflow: auto;

		& .product {
			margin-top: 1em;
		}

		@media (max-width: 960px) {
			width: 100%;
			height: fit-content;
		}
	}
}
