.liquor-validation-modal {
	min-width: 20em;
	min-height: 12em;
	display: flex;
	flex-direction: column;
	align-items: center;

	.validation-title {
		margin-bottom: 0.938rem;
		display: block;
		text-align: center;
	}

	.law-messagge-content {
		display: flex;
		flex-direction: column;
		width: 30em;
		text-align: center;
	}

	.btn-modal-content {
		margin-top: 1em;
		margin-bottom: 1em;
		display: flex;
		flex-direction: row;
		justify-content: center;

		.btn-modal {
			margin-right: 1em;
			margin-left: 1em;
		}
	}

	.error-message {
		//ESTILOS PROVISIONALES
		color: #ce6868;
		display: flex;
		justify-content: center;
		text-align: center;
	}
}

.liquor-validation-modal-mobile {
	min-width: 100%;
	min-height: 12em;
	display: flex;
	flex-direction: column;
	align-items: center;

	.validation-title {
		margin-bottom: 0.938rem;
		display: block;
		text-align: center;
	}

	.law-messagge-content {
		display: flex;
		flex-direction: column;
		width: 15em;
		text-align: center;
	}

	.btn-modal-content {
		margin-top: 1em;
		margin-bottom: 0.5em;
		display: flex;
		flex-direction: row;
		justify-content: center;

		.btn-modal {
			margin-right: 1em;
			margin-left: 1em;
		}
	}

	.error-message {
		//ESTILOS PROVISIONALES
		color: #ce6868;
		display: flex;
		justify-content: center;
		text-align: center;
	}
}
