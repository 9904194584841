.notfound-page-desktop {
	display: flex;
	flex-direction: column;
	align-items: center;

	h2 {
		margin-top: 1rem;
		margin-bottom: 2rem;
		font-size: 150px;
	}

	.goBack {
		margin-top: 3rem;
		cursor: pointer;
		display: flex;
		gap: 1rem;
		align-items: center;
	}
}
