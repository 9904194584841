.cart-message {
	display: flex;
	flex-direction: column;
	align-items: center;

	min-height: 6rem;

	&.-mobile {
		background-color: transparent;
		box-shadow: none;
	}

	span.message {
		text-align: center;
		margin-top: 0.5rem;
		margin-bottom: 1rem;
	}
}
