@import '../base/variables';

.slider-products {
	.arrows-container + div {
		display: flex;
		gap: 1.5rem;
		padding: 1rem;
	}
}

.card {
	background: #ffffff;
	box-shadow: 0px 5px 20px rgba(33, 74, 142, 0.15);
	padding: 1.188rem 0.75rem 0.813rem 0.875rem;

	width: 100%;
	box-sizing: border-box;
	border-radius: 10px;
}

.cards-desktop-container {
	display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 1.5rem;
	margin: 0 1.2rem;
}
.cards-mobile-container {
	display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: repeat(auto-fit, minmax(115px, 1fr));
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 1.5rem;
    margin: 0 1.2rem;
}

.category-mobile-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.5em;
	background: #ffffff;
	box-shadow: 0px 0px 14px rgba(33, 74, 142, 0.3);
	border-radius: 0.5rem;

	width: 7rem;
	min-height: 6rem;

	box-sizing: border-box;
	padding: 0 0.25rem;
	margin: 0.5em 0.5em 2.5em 0.5em;

	& .card-image {
		width: 1.6em;
		height: 1.6em;
	}

	span.category-name {
		width: 100%;
		text-align: center;

		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.productCardDesktop {
	background: #ffffff;
	width: 20em;
	min-height: 19em;
	margin: auto; //0.5em 0.5em 2.5em 0.5em;
	padding: 1em;
	border-radius: 2em;
	box-shadow: 0.3em 0.8em 1em rgba(74, 74, 74, 0.18);
	border: solid #c1c1c196 1px;

	display: flex;
	flex-direction: column;
	justify-content: space-between; /*
	transition-duration: .2s;
	transition-property: width, height;*/
	will-change: transform;
    transition-duration: .2s;

	.main-content {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 1em;
		background-color: white;
		border-radius: 1em 1em 0 0;

		.title_sku-container {
			display: flex;
			flex-direction: column;
			gap: 0.5em;

			.title {
				align-items: flex-start;
				display: flex;
				max-height: 3em;
				overflow: hidden;

				text-overflow: ellipsis;
				width: 100%;
			}

			p {
				padding: 0;
				margin: 0;
			}

			.price-hover {
				display: none;
			}

			.sku_gramo {
				display: flex;
				justify-content: space-between;
				// align-items: center;
			}
		}

		.price {
			display: flex;
			flex-direction: column;
			// align-items: center;

			p {
				margin: 0;
			}
		}

		.image {
			width: 100%;
			height: 60%;
			position: relative;
			display: flex;
			justify-content: center;

			img {
				display: block;
				height: 100%;
				width: 100%;
			}
		}
	}

	.productCard-footer {
		display: none;
		padding-top: .5rem;
	}
}

.productCard-hover {
	background: $primary-blue;
	/*
	width: 20.6em;
	height: 26em;
	*/
	padding: 0.7em;
	//margin: 0em 0.5em 0em 0.5em;
	// nuevo
	scale: 1.1;
	z-index: 2;

	.main-content {
		height: 72%;
		border-radius: 1em;//  1em 1em 0 0;

		.title_sku-container {
			gap: 0.1em;
			flex-direction: column-reverse;
			margin: 0.2em 0.3em;

			.title_price {
				display: grid;
				gap: 0.1em;
				grid-template-columns: 70% 1fr;

				.price-hover {
					justify-self: flex-end;
					display: flex;
					flex-direction: column;
				}
			}
		}

		.price {
			display: none;
		}

		.image {
			height: 62%;
		}
	}

	.productCard-footer {
		display: grid;

		.counter {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-bottom: 0.5em;
			border-bottom: 0.1em solid $border-color-blue;
		}
		.buttons {
			margin-top: 0.5em;
			display: flex;
			justify-content: space-between;
			transition: width 0.1s ease-out, height 0.1s ease-out;

			.buttons-content {
				display: flex;
				gap: 5px;
			}
		}
	}
}

.productCardMobile {
	zoom: 0.7;
	background: #ffffff;
	box-shadow: 0.3em 0.8em 1em rgba(74, 74, 74, 0.18);
	border-radius: 1.25em;

	// width: 12rem;
	min-width: 190px;
	max-width: 60vw;
	height: auto;
	display: grid;
	// grid-template-rows: 1fr 3fr 1fr 1.5fr 1fr;

	margin: 0.5em 0.5em 2.5em 0.5em;
	padding: 1.063rem 0.844em;
	box-sizing: border-box;

	& .product-name {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		a {
			min-width: 170px;
			margin-top: 18px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	& .image {
		width: 100%;
		height: 10rem;

		display: flex;
		justify-content: center;
		overflow: hidden;
		padding-top: 0.5rem;
		a {
			display: flex;
			justify-content: center;
			img {
				width: 7rem;
				max-width: 100%;
				height: 100%;
				height: fit-content;
			}
		}
	}
	& .product-price {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		& .price {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
		}
		& .mayorist {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}
	}
	& .product-actions {
		padding-top: 0.5em;
		gap: 0.2rem;
		width: 100%;
		display: flex;
		justify-content: space-between;
		border-top: 0.1em solid #e3e8f0;
	}

	&.-small {
		width: 41vw;

		.product-actions {
			padding-top: 0.5em;
			gap: 0.2rem;
			width: 100%;
			display: flex;
			justify-content: space-between;
			border-top: 0.1em solid #e3e8f0;
			position: relative;
			left: -0.7em;
		}
	}
}
.productCardDesktop.product-card-margin {
    margin: 1.5rem 1.1rem;// 1.1rem;
	&.productCard-hover {
		scale: 1.1;
	}
}
@keyframes card-hover {
	0% {
		height: 20em;
		margin-bottom: 3em;
	}
	100% {
		height: 23em;
		margin-bottom: 0em;
	}
}

.form-card {
	width: 100%;
	background: #ffffff;
	box-shadow: 0px 5px 20px rgba(33, 74, 142, 0.15);
	border-radius: 10px;

	span.title {
		align-self: flex-start;
	}

	box-sizing: border-box;
	padding: 1.341rem 0.938rem 1.5rem 0.625rem;

	display: flex;
	flex-direction: column;
	align-items: center;

	.body {
		margin-top: 0.971rem;
		margin-bottom: 1.875rem;
	}

	.card-container {
		margin-top: 0.971rem;
		margin-bottom: 1.875rem;
		height: 9em;
		overflow: scroll;
	}

	.label {
		display: block;
		margin-left: 1.5rem;
		margin-bottom: 0.43rem;
	}
}

.floating-card {
	position: fixed;
	bottom: 6.063rem;
	left: 0.75em;
	width: calc(100% - 2 * 0.75em);
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	align-items: center;

	background: #ffffff;
	box-shadow: 0px 5px 20px rgba(33, 74, 142, 0.15);
	border-radius: 10px;
	padding: 0.813em 2.375em;

	z-index: 2;
}

@media (max-width: 359px) {
	.productCardMobile {
		&.-small {
			width: 60vw;

			.product-actions {
				left: 0;
			}
		}
	}
}
