.cart-products-mobile {
	.direcciones-message {
		margin-bottom: 1.198rem;
		.direcciones-link {
			text-decoration: none;
		}
	}

	.cart-products {
		display: flex;
		flex-direction: column;
		align-items: center;

		.cart-lists {
			box-sizing: border-box;
			width: 100%;

			display: flex;
			flex-direction: column;
			gap: 2.7rem;
		}

		.cart-button {
			margin-top: 1.664rem;
		}
	}
}
