.search {
	display: flex;
	align-items: center;
	width: 21.875rem;
	color: $text-color;

	.react-autosuggest__container {
		position: relative;
		width: 100%;

		.react-autosuggest__suggestions-container--open {
			display: block;
			box-sizing: border-box;
			background-color: #fff;
			z-index: 99;
			border-radius: 5px;
			box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
			height: 45vh;
			overflow-y: scroll;
		}

		.react-autosuggest__suggestions-list {
			margin: 0;
			padding: 0;
			list-style-type: none;

			.suggestion {
				box-sizing: border-box;
				display: flex;
				align-items: center;
				padding: 1rem;
				border-top: 1px solid $border-color;
				cursor: pointer;
				gap: 0.5rem;
				.image-container {
					max-height: 4rem;
					max-width: 4rem;
					overflow: hidden;
					border-radius: 1rem;
					img {
						height: 4rem;
					}

					.image {
						height: 4rem;
					}
				}

				.info {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;

					.name {
						margin-bottom: 0.5rem;
					}
				}

				&:hover {
					background-color: $blue-light;
				}
			}

			& ~ .search-more {
				display: flex;
			}
		}

		.search-more {
			display: none;
			border-top: 1px solid $border-color;
			justify-content: center;
			padding: 0.8rem;

			cursor: pointer;
			border-radius: 5px;
			overflow: hidden;

			&:hover {
				background-color: $blue-light;
			}

			span {
				width: 100%;
				max-width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: center;
				strong {
					color: $primary-blue;
				}
			}
		}
	}

	#react-autowhatever-1 {
		position: absolute;
		width: 100%;
		top: 3.125rem;
		margin-top: 0.8rem;
		border-radius: 1rem;
	}
}
