//input en formulario de login
.o-input-login {
	margin: 8px 0 8px;
	background: $blue-light;
	width: 230px;
	height: 35px;
	border: 1px solid #e9e9e9;
	border-radius: 30px;
	padding-left: 1rem;
	padding-right: 1rem;
	outline: none;
}

//select
.o-input-dropdown {
	select {
		border: none;
		outline: none;
		width: 100%;
		height: 100%;
		background: none;
		background-image: url(../../../assets/icons/arrow-blue-down.svg);
		background-repeat: no-repeat;
		background-position: right center;
		-webkit-appearance: none;
		-moz-appearance: none;
		-o-appearance: none;
		appearance: none;
		color: #858585;
	}
	select::-ms-expand {
		display: none;
	}
}

.searchInput {
	min-width: calc(90% - 3.1em);
	border-radius: 2em;
	background: #fff;
	display: grid;
	grid-template-columns: 0.5fr 2fr 1fr;
	&.-with-shadow {
		box-shadow: 0px 0px 0.9em rgba(0, 0, 0, 0.11);
	}
	i {
		position: relative;
		margin-top: 1.3em;
		margin-left: 0.5em;
		padding-right: 1em;
		border-right: 0.1em solid #e9e9e9;
	}
	.buscar {
		display: flex;
		justify-content: center;
		align-items: center;
		button {
			width: 85%;
			height: 85%;
			border: none;
			border-radius: 2em;
			background: #e3e8f0;
			cursor: pointer;
		}
	}

	input {
		border: none;
		height: 28.8px;
		padding: 1.2em 0em 1.2em 0em;
		min-width: calc(90% - 3.1em);
		&:focus {
			outline: none;
		}
	}
}

// estilos de los campos del componente EditarDatos de la página mi cuenta
.edit-data-input-text {
	width: 100%;

	span.label {
		display: block;
		margin-left: 1.5rem;
		margin-bottom: 0.43rem;
	}

	.input {
		width: 100%;
		min-height: 2.5rem;

		border: 1px solid $input-color;
		border-radius: 30px;

		box-sizing: border-box;
		padding: 0 1.05rem;

		display: flex;
		align-items: center;

		.endAdornment {
			white-space: nowrap;
			color: $text-color-light;
			font-weight: normal;
			font-size: 0.87rem;
			margin-bottom: 0;
		}
		textarea {
			padding-top: 0.5rem;
		}
		input,
		textarea {
			width: inherit;
			height: inherit;
			background: transparent;
			border: none;
			flex-grow: 1;

			color: $text-color-light;
			font-weight: normal;
			font-size: 0.87rem;
			line-height: 14px;

			&:focus {
				outline: none;
			}

			&:disabled {
				color: #bfbfbf;
			}
		}

		input[type='number']::-webkit-inner-spin-button,
		input[type='number']::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		input[type='number'] {
			-moz-appearance: textfield;
		}
	}
	.textarea {
		border-radius: 1rem;
	}
	span.error-message {
		//ESTILOS PROVISIONALES
		color: $text-error;
		margin-left: 1.5rem;
	}

	&.-white {
		.input {
			background-color: #fff;
			input:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 30px #fff inset !important;
			}
		}
	}
	&.-blue {
		.input {
			background-color: $blue-light;
			input:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 30px $blue-light inset !important;
			}
		}
	}
}

// estilos de text area
.edit-data-text-area {
	width: 100%;

	span.label {
		display: block;
		margin-left: 1.5rem;
		margin-bottom: 0.43rem;
	}

	.input {
		width: 100%;

		border: 1px solid $input-color;
		border-radius: 20px;

		box-sizing: border-box;
		padding: 0.5rem 1rem;

		display: flex;
		align-items: center;

		textarea {
			width: inherit;
			height: inherit;
			background: transparent;
			border: none;
			flex-grow: 1;

			color: $text-color-light;
			font-weight: normal;
			font-size: 0.87rem;
			line-height: 14px;

			&:focus {
				outline: none;
			}

			&:disabled {
				color: #bfbfbf;
			}
		}
	}
	span.error-message {
		//ESTILOS PROVISIONALES
		color: $text-error;
		margin-left: 1.5rem;
	}

	&.-white {
		.input {
			background-color: #fff;
			input:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 30px #fff inset !important;
			}
		}
	}
	&.-blue {
		.input {
			background-color: $blue-light;
			input:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 30px $blue-light inset !important;
			}
		}
	}
}

// phone input
.phone-field {
	width: 100%;

	span.label {
		display: block;
		margin-left: 1.5rem;
		margin-bottom: 0.43rem;
	}

	.phone-field-container {
		width: 100%;

		input.phone-field-input {
			width: inherit;
			height: 2.5rem;

			border: 1px solid $input-color;
			border-radius: 30px;

			box-sizing: border-box;
			padding-top: 0;
			padding-bottom: 0;
			padding-right: 1.35rem;

			color: $text-color-light;
		}

		div.phone-field-button.flag-dropdown {
			border-radius: 30px 0 0 30px;
			background: transparent;
			border: none;
			padding-left: 6px;

			div.selected-flag.open {
				border-radius: 30px 0 0 30px;
				background: transparent;
				border: none;
				padding-left: 6px;
			}

			div.selected-flag:hover {
				background-color: transparent;
			}
			div.selected-flag:focus {
				background-color: transparent;
			}
		}
	}

	span.error-message {
		margin-left: 1.5rem;
		color: $text-error;
	}

	&.-white {
		.phone-field-container input.phone-field-input {
			background-color: #fff;
		}
	}
	&.-blue {
		.phone-field-container input.phone-field-input {
			background-color: $blue-light;
		}
	}
}

.edit-data-select {
	width: 100%;

	&.-disabled {
		opacity: 0.4;
	}

	span.label {
		display: block;
		margin-left: 1.5rem;
		margin-bottom: 0.43rem;
	}

	.select-input {
		width: 100%;
		background-color: #fff;
		border: 1px solid $input-color;
		border-radius: 30px;

		box-sizing: border-box;

		&.-small {
			height: 1.5rem;
			padding: 0 0.25rem;
		}

		&.-big {
			height: 2.5rem;
			padding: 0 1.25rem;
		}

		&.-white {
			background-color: #fff;
		}
		&.-blue {
			background-color: $blue-light;
		}
		select {
			height: 100%;
			width: 100%;
			background: transparent;
			font-weight: normal;
			font-size: 0.87rem;
			line-height: 14px;
			color: $text-color-light;
			border: none;
			outline: none;
			background: none;
			appearance: none;

			background-image: url(../../../assets/icons/arrow-blue-down.svg);
			background-repeat: no-repeat;
			background-position: right center;
		}

		&:focus {
			outline: none;
		}
	}
}

.small-transparent-select {
	background-color: transparent;
	width: 1.144rem;
	border: none;

	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	position: relative;

	select {
		height: 100%;
		width: 100%;
		background: transparent;
		font-weight: normal;
		font-size: 0.563em;
		color: $text-color-light;
		border: none;
		outline: none;
		background: none;
		appearance: none;
		cursor: pointer;

		background-image: url(../../../assets/icons/arrow-blue-down.svg);
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: 10px;

		&.arrow-up {
			background-image: url(../../../assets/icons/arrow-blue-up.svg);
		}
		&.arrow-down {
			background-image: url(../../../assets/icons/arrow-blue-down.svg);
		}
	}
}

//input de formulario en direcciones
.input-whitebackground {
	input {
		background: $blue-light;
	}
}

.dark-blue-select {
	background: $primary-blue;
	padding: 0.5em 0em 0.5em 0em;
	border-radius: 2em;
	width: 100%;
	.select-input {
		width: 100%;
		.select-container {
			height: 100%;
			width: 100%;
			background: $primary-blue;
			@extend .text-12px-600;
			color: #fff;
			border: none;
			outline: none;
			background: none;
			appearance: none;

			background-image: url(../../../assets/icons/arrow_white_down.svg);
			background-repeat: no-repeat;
			background-position: right center;

			p {
				line-height: 21px;
				margin: 0.1px 0 0;
			}

			&:hover {
				outline: none;
				background-image: url(../../../assets/icons/arrow_white_up.svg);
			}
		}

		ul {
			background-color: #ffffff;
			padding: 0;
			padding-left: 20px;
			padding-right: 20px;

			border-radius: 5px;
			box-shadow: 0px 0px 12px rgba(33, 74, 142, 0.23);
			margin-top: 1em;
			position: fixed;
			z-index: 4;
			width: 30vw;
			li {
				background: #ffffff;
				color: $primary-blue;
				// width: 10vw;
				padding-top: 12px;
				padding-bottom: 12px;

				border-bottom: 1px solid #e9e9e9;
				list-style: none;
				font-size: 12px;
			}
			li:last-child {
				border-bottom: none;
			}
		}
		width: 100%;
		height: 2.5rem;
		border-radius: 30px;

		box-sizing: border-box;
		padding: 0.625rem 1.25rem;
	}
}

// auto complete
.auto-complete {
	width: 100%;

	span.label {
		display: block;
		margin-left: 1.5rem;
		margin-bottom: 0.43rem;
	}
}

//input-cupon
.input-cupon {
	width: 100%;
	box-sizing: border-box;
	background: #ffffff;
	box-shadow: 0px 5px 20px rgba(33, 74, 142, 0.15);
	border-radius: 10px;

	padding: 0.55rem 0.75rem 0.55rem 0.75rem;

	.body {
		display: flex;
		justify-content: space-between;
		align-items: center;

		span {
			cursor: pointer;
			text-align: center;
		}

		input {
			width: 65%;
			height: 1.8em;
			background-color: $blue-light;
			border: 1px solid $border-color;
			border-radius: 30px;

			padding-left: 8px;
			&:focus {
				outline: none;
			}
		}
	}

	span.success-message {
		//ESTILOS PROVISIONALES
		color: #86c947;
	}

	span.error-message {
		//ESTILOS PROVISIONALES
		color: #ce6868;
	}
}

//input checkbox
.input-checkbox {
	width: 100%;
	box-sizing: border-box;
	background: #ffffff;
	display: flex;
	border-radius: 10px;

	padding: 0.55rem 0.75rem 0.55rem 0.75rem;

	:hover {
		color: blue;
	}
}
