.floating-summary {
	.message {
		text-align: center;
		margin-bottom: 0.625rem;
	}

	.subtotal {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0.5em;
	}
}
