.slider__container {
	position: relative;
	overflow: hidden;
	.slider {
		display: flex;
		overflow-x: scroll;
		scroll-snap-type: x mandatory;
		position: relative;
		&::-webkit-scrollbar {
			display: none;
		}
	}
}
