.searchResultMobile {
	.category-section {
		margin-left: 1em;
		margin-right: 1em;
		& .title {
			display: flex;
			flex-wrap: wrap;
			margin-top: 0.5em;
			margin-bottom: 0.5em;

			p {
				margin-right: 0.5em;
				margin-bottom: 0;
				margin-top: 0.5em;
			}
		}
	}

	.containerProducts {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.circularProgress {
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 1em;
	}
}

@media (max-width: 359px) {
	.searchResultMobile {
		.containerProducts {
			justify-content: center;
		}
	}
}
