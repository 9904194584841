.formAddressDesktop {
	background: white;
	box-shadow: 0px 5px 20px rgba(33, 74, 142, 0.15);
	border-radius: 10px;
	padding: 10px;
	padding-top: 20px;
	.margin-form {
		margin-top: 20px !important;
	}

	span.error-message {
		//ESTILOS PROVISIONALES
		color: $text-error;
		margin-left: 1.5rem;
		margin-bottom: 1rem;
	}

	.btn-container {
		display: flex;
		button {
			margin-left: 0.5em;
			margin-right: 0.5em;
		}
	}
}

@media (max-width: 1020px) {
	.formAddressDesktop {
		margin-top: 1em;
	}
}
