.historial-mobile {
	.title {
		display: block;
		margin-bottom: 2.196rem;
	}

	.order-container {
		display: flex;
		flex-direction: column;
		gap: 0.875rem;
	}
}

.order-details {
	display: flex;
	flex-direction: column;
	gap: 2.3rem;

	.historial-section {
		display: flex;
		flex-direction: column;
		gap: 0.768rem;
	}
	.product-lists {
		display: flex;
		flex-direction: column;
		gap: 2rem;
	}
}
