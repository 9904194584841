.floating-finish-order {
	padding: 1.344em 0.875em !important;

	.message {
		margin-bottom: 0.625rem;
	}

	.finish-btn {
		width: 100%;
		height: 40px;

		justify-content: space-between;
		padding: 0.625rem 1.563rem;
	}
}
