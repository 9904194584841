@mixin color-variations {
	&.-light-blue {
		background-color: $blue-light;
	}
	&.-blue {
		background-color: $border-color;
	}
	&.-white {
		background-color: #fff;
	}
}

.product-counter {
	.unit-type {
		&.-underline {
			text-decoration: underline;
		}
		&.-disabled {
			opacity: 0.4;
		}
	}

	display: flex;
	align-items: center;

	&.-big {
		gap: 0.4rem;
	}
	&.-small {
		gap: 0.25rem;
	}

	.counter-label {
		box-sizing: border-box;
		padding: 10px;

		display: flex;
		align-items: center;
		justify-content: center;

		border-radius: 30px;

		&.-big {
			height: 36px;
			min-width: 40px;
		}
		&.-small {
			height: 25px;
		}
		@include color-variations;
	}

	.product-counter-button {
		padding: 10px;

		border-radius: 30px;
		border: none;

		display: flex;
		align-items: center;
		justify-content: center;

		cursor: pointer;
		span {
			display: flex;
			justify-content: center;
		}
		&:disabled {
			cursor: not-allowed;
		}

		&.-big {
			width: 36px;
			height: 36px;
		}
		&.-small {
			width: 25px;
			height: 25px;
		}
		@include color-variations();
	}
}

@media (max-width: 800px) {
	.product-counter.-big {
		&.-with-grams {
			/*display: grid;
			grid-template-columns: 1fr 1fr 1fr;*/
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 0.5rem;
		}
		&.-without-grams {
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 0.5rem;
			:first-child{
				width: 100%;
			}
		}
	}
}