.cart-page-desktop {
	.cart-body {
		height: 580px;

		//breakpoints
		@media screen and (max-width: 970px) {
			height: fit-content;
		}

		.cart-products {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.cart-lists {
				@media screen and (max-width: 960px) {
					height: fit-content;
				}

				box-sizing: border-box;
				width: 100%;
				height: 100%;
				overflow: auto;

				display: flex;
				flex-direction: column;
				gap: 2.7rem;
				padding: 0 1rem;
			}

			.cart-button {
				margin-top: 1.664rem;
			}
		}

		.payment-methods-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 1.563rem;
		}
	}
}

@import './CartMessage/cartMessage';
