.mercados-mobile {
	display: flex;
	flex-direction: column;
	gap: 2.188rem;
}

.mercados-container {
	display: flex;
	flex-direction: column;
	gap: 0.875rem;
}

.mercados-products-container {
	display: flex;
	flex-direction: column;

	.mercado-name {
		display: block;
		margin-bottom: 1.563rem;
	}
	.product-lists {
		display: flex;
		flex-direction: column;
		gap: 2.7rem;
	}
	.empty-button {
		align-self: center;
		margin-top: 2.094rem;
		width: 10.625rem;
	}
}
